<template>
  <div class="container">
    <script type='application/ld+json' v-text='jsonld'></script>
    <div class="txt-item menu-margin">
      <h2>КУРСЫ ВОДИТЕЛЕЙ МЕЖДУНАРОДНИКОВ</h2>
      <p>Курсы водителей международников пользуются большой популярностью среди дальнобойщиков, проработавших несколько лет в грузоперевозках. Но даже такой интерес к профессии не снижает нехватки профессиональных кадров из-за высокой сложности работы и высоких требований.</p>
      <p>Это высокооплачиваемая профессия, привлекающая многих. Но и она имеет свои недостатки, как и преимущества.</p>
      <p><strong>Недостатки:</strong></p>
      <p>
        <ul>
          <li>повышенная ответственность - стоимость одного автопоезда варьируется 100 - 300 тысяч евро, а стоимость груза – может достигать сотни тысяч евро;</li>
          <li>повышенный уровень стресса;</li>
          <li>напряженный режим работы.</li>
      </ul>
      </p>
      <p><strong>Преимущества:</strong></p>
      <p>
        <ul>
          <li>высокая оплата труда;</li>
          <li>низкая конкуренция и дефицит квалифицированных кадров;</li>
          <li>путешествия по разным странам.</li>
      </ul>
      </p>
    </div>
    <div class="c-item bg">
      <v-img
        src="img/logo/truck.png"
        max-width=250
      ></v-img>
      <p><strong>Курсы водителей международников</strong></p>
      <span>Стоимость - <strong>{{getCost.truck[0].base}} BYN</strong></span>
      <br>
      <span>Продолжительность - {{getCost.truck[0].day}} дней</span>
    </div>
    <div class="doc">
      <TabsTruck/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import TabsTruck from '@/components/TabsTruck.vue'
export default {
  components: {TabsTruck},
  metaInfo: {
    title: 'Курсы по подготовке водителей международников',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Курсы по подготовке водителей международников в Витебске. Курсы водителей, курсы международников.',
      }
    ]
  },
  data: () => ({
    jsonld: {
      "@context": "http://www.schema.org",
      "@type": "LocalBusiness",
      "name": "ADR - ВитАвтоУниверсал",
      "url": "http://by.vit-avto.by/truck",
      "logo": "http://by.vit-avto.by/img/logo/logoB.png",
      "description": "Курсы по подготовке водителей международников в Витебске. Курсы водителей, курсы международников.",
      "image": "http://by.vit-avto.by/img/logo/truck.png",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "пр-т Фрунзе, 8",
        "addressLocality": "Витебск",
        "addressCountry": "Беларусь"
      },
      "hasMap": "https://goo.gl/maps/Uwb88CPAPiHky3ye6",
      "openingHours": "Mo, Sa, Su 10:00-20:00 Tu, We, Th, Fr 09:00-20:00",
      "telephone": "+375 29 725-86-25"
    },
  }),
  computed: {
    ...mapGetters(['getCost'])
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
    })
  }
}
</script>

<style lang="scss" scoped>
  .txt-item {
    font-weight: 400;
    font-style: normal;
    background-color: #e9e9e9;
    padding: 25px;
    border-radius: 5px;
    box-shadow: -20px 20px 0 -17px #fff, 
                20px -20px 0 -17px #fff, 
                20px 20px 0 -20px rgb(165, 165, 165), 
                0 0 0 2px rgb(165, 165, 165);
  }
  .c-item {
    width: 100%;
    border-radius: 5px;
    text-align: center;
    padding: 25px 0;
    margin: 25px 0;
    .v-image {
      margin: 0 auto;
    }
    .v-btn {
      margin-top: 25px;
    }
  }
</style>