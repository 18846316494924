<template>
  <div class="tabs-adr bg">
    <v-tabs
      v-model="tab"
      background-color="#e9e9e9"
      show-arrows
    >
      <v-tab>Описание</v-tab>
      <v-tab>Инструкции</v-tab>
      <v-tab>Условия обучения</v-tab>
    </v-tabs>
    
    <v-divider></v-divider>

    <v-tabs-items v-model="tab">

      <v-tab-item>
        <v-card flat>
          <div class="content">
            <p><strong>Для получения квалификационной карточки водителя</strong>, который выполняет международные автомобильные перевозки грузов, необходимо пройти обучение на профессиональную компетентность и проверку знаний в форме экзамена в учреждении образования, а в дальнейшем не реже одного раза в пять лет сдавать экзамен после прохождения обучения на профессиональную компетентность в учебной организации с получением квалификационной карточки. </p>
            <p><strong>Водители-международники должны пройти обучающий курс, в котором изучают:</strong></p>
            <p>
              <ul>
                  <li>нормы и ПРАВИЛА автомобильных перевозок грузов;</li>
                  <li>законодательную базу и нормативно-правовые акты;</li>
                  <li>основы логистики;</li>
                  <li>режим труда и отдыха;</li>
                  <li>получают практические навыки;</li>
                  <li>оформление транспортных документов;</li>
                  <li>сферу охраны труда и многое другое.</li>
              </ul>
            </p>
            <p><strong>Водитель-международник для эффективной трудовой деятельности должен обладать рядом навыков и определенной базой знаний, в частности:</strong></p>
            <p>
              <ul>
                <li>хорошо знать правовую базу;</li>
                <li>понимать основы организации международных перевозок;</li>
                <li>изучить положения Конвенций КДПГ, МДП, АТА, ЕСТР;</li>
                <li>знать классификацию всех грузов, стандарты их перевозок;</li>
                <li>уметь читать техническую документацию и применять ее на практике;</li>
                <li>иметь навыки ремонта транспорта;</li>
                <li>правильно составлять и заполнять документы;</li>
                <li>закреплять грузы для надежной перевозки;</li>
                <li>знать правила таможенного контроля.</li>
              </ul>
            </p>
            <p><strong><v-icon left>mdi-alert-box</v-icon>По окончании курсов выдается КВАЛИФИКАЦИОННАЯ КАРТОЧКА ВОДИТЕЛЯ и справка об обучении.</strong></p>
            <v-img
              src="img/doc/truck_card.jpg"
              max-width=250
            ></v-img>
            <v-img
              src="img/doc/truck_card_2.jpg"
              max-width=250
            ></v-img>
          </div>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <div class="content">
            <p><strong>Требования к водителям для зачисления в учебную группу</strong></p>
            <ul>
              <li>не моложе 21 года.</li>
            </ul>
            <br>
            <p><strong>Документы для зачисления</strong></p>
            <ul>
              <li>документ, удостоверяющий личность.</li>
            </ul>
          </div>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <div class="content">
            
            <table class="table">
              <thead>
                <tr>
                  <th colspan="2">Слушатели</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Категория слушателей</td>
                  <td>водители</td>
                </tr>
              </tbody>
              <thead>
              <tr>
                <th colspan="2">Программа</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Тип программы</td>
                <td>обучающие курсы</td>
              </tr>
              <tr>
                <td>Срок обучения</td>
                <td>5 дней</td>
              </tr>
              <tr>
                <td>Форма итоговой аттестации</td>
                <td>проверка знаний</td>
              </tr>
              <tr>
                <td>Выдаваемые документы</td>
                <td>квалификационная карточка водителя, справка об обучении</td>
              </tr>
            </tbody>
            </table>
            
          </div>
        </v-card>
      </v-tab-item>

    </v-tabs-items>

  </div>
</template>

<script>
export default {
  data:() => ({
    tab: null,
    items: ['Описание', 'Инструкции', 'Условия обучения'],
  })
}
</script>

<style lang="scss" scoped>
  .tabs-adr {
    padding: 10px;
    .content {
      padding: 19px 10px;
      text-align: left;
      color: #696969;
      i {
        margin-bottom: 0px;
      }
      .v-image {
        display: inline-block;
        margin: 0 5px;
      }
    }
    .v-tab {
      font-weight: 600;
      color: #000000 !important;
      &.v-tab--active {
        color:#f27937 !important;
      }
    }
    .v-card {
      background-color: #e9e9e9;
      &.v-sheet {
        border-radius: 0px;
      }
    }
    .theme--light.v-tabs-items {
      background-color: #D3D3D3;
    }
    .table {
      width: 100%;
      border-collapse: collapse;
      th, td {
        padding: 10px 15px;
        border: 1px solid #D3D3D3;
      }
      thead {
        background-color: #DCDCDC;
      }
      tbody {
        td {
          &:nth-child(1) {
            width: 25%;
          }
        }
      }
    }
  }
</style>
