import { render, staticRenderFns } from "./TabsTruck.vue?vue&type=template&id=e360a640&scoped=true&"
import script from "./TabsTruck.vue?vue&type=script&lang=js&"
export * from "./TabsTruck.vue?vue&type=script&lang=js&"
import style0 from "./TabsTruck.vue?vue&type=style&index=0&id=e360a640&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e360a640",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VDivider,VIcon,VImg,VTab,VTabItem,VTabs,VTabsItems})
